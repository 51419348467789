import { Injectable } from "@angular/core";
import { Adapter } from "../../adapters";
import { IUserAPI } from "../../models";
import { UserAdapter } from "./user.adapter.service";



@Injectable({
    providedIn: "root"
})

export class ApiAdapter implements Adapter<IUserAPI> {

    constructor(private adapterPackage: UserAdapter) {

    }

    adapt(response: any): IUserAPI {

        let userData = this.adapterPackage.adapt(response.data);
        
        return {
            keyword: response.keyword,
            message: response.message,
            user: userData,
            pageSize: response?.total_size ?? 0,
            page: response?.page ?? 0,
        }
    }

}