<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.sidebarStyle !== 'compact'" mat-icon-button id="sidenavToggle" (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>

  <!-- Search form -->
  <!-- <div fxFlex fxHide.lt-sm="true" class="search-bar">
    <form class="top-search-form">
      <mat-icon role="img">search</mat-icon>
      <input autofocus="true" placeholder="Search" type="text" />
    </form>
  </div> -->

  <span fxFlex></span>


  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <!-- <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
  >
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button> -->
  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <img src="assets/images/avartar.jpg" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/my-account/change-password']">
      <mat-icon>lock</mat-icon>
      <span>Change Password</span>
    </button>
    <!-- <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span> Settings</span>
    </button> -->
    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>