import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './user.service'; 
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
 
    return this.userService.isLoggedIn         
      .pipe(
        take(1),                              
        map((isLoggedIn: boolean) => {     
          if (!isLoggedIn) {
            // this.router.navigate(['/']); 
            this.userService.logout(); 
            return false;
          }
          return true;
        })
      );



  }
}
