import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

// import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import { RoutePartsService } from './core/services/route-parts.service';
import { getToken, decodedToken } from './core/helpers/token.helper';
import { NavigationService } from './core/services/navigation.service';
import { PermissionService } from './core/services/permission.service';
// import { LayoutService } from './shared/services/layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Echo App';
  pageTitle = '';

  constructor(
    public title: Title, 
    private router: Router, 
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private permSer : PermissionService,
    private navSer : NavigationService
    // private themeService: ThemeService,
    // private layout: LayoutService,
    // private renderer: Renderer2
  ) {
    this.getAuthMenus()
   }

  ngOnInit() {
    this.changePageTitle();
  }

  ngAfterViewInit() {
  }
  
  getAuthMenus(){
    const token = decodedToken();
    console.log(token, "token")
    if(getToken()){
      this.permSer.init(Number(token.user_id), token.permission);
      let authMenu = this.permSer.authMenu;
      this.navSer.publish(authMenu);
    }
  }
  
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
  
}
