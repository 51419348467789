import { Pipe, PipeTransform } from "@angular/core";
import { format, isValid } from 'date-fns';
import { getDateFnsFormat, getDateTimeFnsFormat } from 'app/core/helpers/app-config.helper';
import * as moment from "moment";
import { formatNumber } from "@angular/common";

@Pipe({ name: 'displayDate' })
export class AppDisplayDatePipe implements PipeTransform {
    transform(value: string): string {
        return value?.length > 0 ? format(new Date(value), 'MM/dd/yyyy') : '';
    }
}

@Pipe({ name: 'displayDateTime' })
export class AppDisplayDateTimePipe implements PipeTransform {
    transform(value: string): string {
        var dateValue = new Date(value);
        return isValid(dateValue) ? format(dateValue, 'MM/dd/yyyy hh:mm a') : '';

    }
}
@Pipe({ name: 'displayTime' })
export class AppDisplayTimePipe implements PipeTransform {
    transform(value: string): string {
        var dateValue = new Date(value);
        return isValid(dateValue) ? moment.utc(dateValue).format('MM/DD/YYYY hh:mm A') : '';

    }
}
@Pipe({ name: 'displayNumber' })
export class AppDisplayNumberPipe implements PipeTransform {
    transform(value: any, format: string) {
        if (value == null) { return ''; } // !value would also react to zeros.
        if (!format) { format = 'en-US'; }

        return formatNumber(value, format);
    }
}
@Pipe({ name: 'displayCurrency' })
export class AppDisplayCurrencyPipe implements PipeTransform {
    transform(value: any, format: string) {
        if (value == null) { return ''; } // !value would also react to zeros.
        if (!format) { format = 'en-US'; }

        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }
}


