export const APP_VARIABLES = {
    payKey: 'rzp_test_XAwbRDSItDjjI0',
    userId: 'USERID',
    adminProfile: 'ADMINPROFILE',
    isAccountCreated: 'ACCOUNTCREATED',
    login: 'LOGIN',
    /* ieokeysecret: 'Tsk_26c5a7bae64741ed9d1c47bc732c783d',
     ieopublickey: 'Tpk_5c7140afdb63427dbd892a7dd6fd49d7',*/
    ieokeysecret: 'sk_e328cad5acc24d7599ce23417c522bd0', // live 
    ieopublickey: 'pk_9462f8efc8b54a1b9f9f0c20ece60225', // live 
    table: {
        filtering: {
            pageSizeOptions: [10, 25, 50],
        }
    },

    config: {
        lang: 'TGLANG',
        dateFormat: 'TGDATEFORMAT',
        dateRawFormat: 'TGDATERAW',
        dateSep: 'TGDATESEP',
        timeFormat: 'TGTIMEFORMAT',
        themeColor: 'TGTHEME',
        themeFont: 'TGFONT',
        studentId: 'STUDENTID', // for parent portal
        academicId: 'ACADEMICYEARID',// for teacher portal
        batchId: 'BATCHID',// for teacher portal
        academicName: 'ACADEMICNAME',// for teacher portal
        batchName: 'BATCHNAME',// for teacher portal
    },

    validators: {
        image: /(.*?)\.(jpg|jpeg|png|JPG|JPEG|PNG)$/,
        integer: /^-?(0|[1-9]\d*)?$/,
        decimalTwoDigitOnly: /^\d{1,1000}(\.\d{1,2})?$/, // allows plus value and two digit
        decimalTwoDigitAndPers: /^\d{1,100}(\.\d{1,2})?$/, // allows plus value and two digit
        decimal: /^\-?(\d+\.?\d*|\d*\.?\d+)$/, // allows + or - values 
        sixDigitInteger: /^[0-9]{1,6}$/,
        tenDigitInteger: /^[0-9]{10}$/,
        alphaNumeric: /^[0-9a-zA-Z]+$/, // RSDFS3454fgfet 
        lettersOnly: /^[A-Za-z]+$/, // ABCabcRtvd
        imageAndPdf: /(.*?)\.(jpg|jpeg|png|bmp|pdf)$/,
        removeWhitespace: /^[^ ][\w\W ]*[^ ]/,
        removeWSWLetter: /^\S$|^\S[\s\S]*\S$/
    }




}