<mat-nav-list>
  <mat-list-item>
      <svg class="icon-bottom-sheet icon-facebook">
          <use xlink:href="#icon-facebook" />
      </svg>
      <a mat-list-item href="https://www.facebook.com/sharer.php?u=https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258">
        <span mat-line>Share on Facebook</span>
      </a>
  </mat-list-item>
  <mat-list-item>
      <svg class="icon-bottom-sheet icon-facebook">
          <use xlink:href="#icon-twitter" />
      </svg>
      <a mat-list-item href="https://twitter.com/intent/tweet?url=https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258&hashtags=angular,template,landing">
        <span mat-line>Tweet About Us!</span>
      </a>
  </mat-list-item>
  <mat-list-item>
      <svg class="icon-bottom-sheet icon-linkedin">
        <use xlink:href="#icon-linkedin" />
      </svg>
      <a mat-list-item href="https://www.linkedin.com/shareArticle?mini=true&url=https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258">
        <span mat-line>Share on LinkedIn</span>
      </a>
  </mat-list-item>
</mat-nav-list>
