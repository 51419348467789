import { ApiService } from './api.service';
import { UserService } from './user.service';
import { ToastService } from './toast.service';
import { ErrorHandlerService } from 'app/core/services/error-handler.service';
import { CustomizerService } from 'app/core/services/customizer.service';
import { CopierService } from 'app/core/services/copier.service';
import { LandingPageService } from 'app/core/services/landing-page.service';
import { LayoutService } from 'app/core/services/layout.service';
import { LocalStoreService } from 'app/core/services/local-store.service';
import { MatchMediaService } from 'app/core/services/match-media.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { RoutePartsService } from 'app/core/services/route-parts.service';
import { ThemeService } from 'app/core/services/theme.service';
import { WindowRef } from 'app/core/services/windowRef.service';
import { HttpCacheService } from 'app/core/services/http-cache.service';
import { PermissionService } from './permission.service';
import { ConfigService } from './config.service';

export const serviceProvider = [
    ApiService,
    UserService,
    ToastService,
    ErrorHandlerService,
    CustomizerService,
    ConfigService,
    CopierService,
    LandingPageService,
    LayoutService,
    LocalStoreService,
    MatchMediaService,
    NavigationService,
    RoutePartsService,
    ThemeService,
    ToastService,
    WindowRef,
    HttpCacheService,
    PermissionService
];